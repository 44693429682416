/* eslint-disable no-undef */
import React from "react"
import { Provider } from "react-redux"

import { createStore, applyMiddleware, compose } from "redux"
import allReducers from "./src/reducers"
import thunk from "redux-thunk"

import { persistStore, persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"

import { PersistGate } from "redux-persist/integration/react"
import { version } from "./package.json"
// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element }) => {
  const persistConfig = {
    key: "root",
    version: parseInt("1" + version.replace(new RegExp(/\./gi), "")),
    storage,
    migrate: state =>
      typeof state === "undefined"
        ? Promise.resolve([])
        : state._persist.version !== parseInt("1" + version.replace(new RegExp(/\./gi), ""))
        ? Promise.resolve([])
        : Promise.resolve(state),
  }

  const persistedReducer = persistReducer(persistConfig, allReducers, 42)

  const composeEnhancers = process.env.NODE_ENV === "development" ? typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose : compose

  // const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__  || compose;

  // const composeEnhancers = process.env.NODE_ENV === "development" ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__  &&
  //         window.__REDUX_DEVTOOLS_EXTENSION__() || compose : f => f;

  const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(thunk)))

  // const store = createStore(persistedReducer, compose(
  //     applyMiddleware(thunk),
  //     process.env.NODE_ENV !== 'production' &&
  //     window.devToolsExtension ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__  : f => f
  //   ));

  const persistor = persistStore(store)

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {element}
      </PersistGate>{" "}
    </Provider>
  )

  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  // const store = createStore()
  // return <Provider store={store}>{element}</Provider>
}
