import { login, fetchPasseInit, fetchPasseChanged, refreshAuthFalse, removeAllProducts, logoutR } from "./../actions"
//import { navigate } from "gatsby"

export const inscription = infos => {
  var url = process.env.GATSBY_API_URL + "/index.php?controller=authentification"
  return dispatch => {
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        action: "inscription",
        infos: infos,
        site: process.env.SITE,
      }),
    })
      .then(function(response) {
        return response.json()
      })
      .then(function(result) {
        if (result.status == "error") {
          dispatch(refreshAuthFalse())
        } else {
          dispatch(login(result))
        }
      })
      .catch(function(error) {
        console.log("Request failed", error)
      })
  }
}

export const accessrequest = infos => {
  var url = process.env.GATSBY_API_URL + "/index.php?controller=authentification"
  return dispatch => {
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        action: "accessrequest",
        infos: infos,
        site: process.env.SITE,
      }),
    })
      .then(function(response) {
        return response.json()
      })
      .then(function(result) {
        if (result.status == "error") {
          dispatch(refreshAuthFalse())
        } else {
          dispatch(fetchPasseInit())
          dispatch(clearError())
          if (typeof window !== "undefined") {
            window.location.href = "/confirmation-demande";
          }
        }
      })
      .catch(function(error) {
        console.log("Request failed", error)
      })
  }
}

export const addNewAdress = data => {
  var url = process.env.GATSBY_API_URL + "/index.php?controller=authentification"
  return (dispatch, getState) => {
    dispatch(fetchPasseInit())
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        action: "addAddress",
        email: getState().identifiants.identifiants.email,
        hash: getState().identifiants.identifiants.hash,
        site: process.env.SITE,
        data: data,
      }),
    })
      .then(function(response) {
        dispatch(fetchPasseChanged()) // pour faire une rederiction
        return response.json()
      })
      .then(function(result) {
        dispatch(fetchPasseInit())
        dispatch(login(result))
      })
      .catch(function(error) {
        console.log("Request failed", error)
      })
  }
}

export const updateAdress = (id_address, data) => {
  var url = process.env.GATSBY_API_URL + "/index.php?controller=authentification"
  return (dispatch, getState) => {
    dispatch(fetchPasseInit())
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        action: "updateAdress",
        email: getState().identifiants.identifiants.email,
        hash: getState().identifiants.identifiants.hash,
        site: process.env.SITE,
        id_address: id_address,
        data: data,
      }),
    })
      .then(function(response) {
        dispatch(fetchPasseChanged()) // pour faire une rederiction
        return response.json()
      })
      .then(function(result) {
        dispatch(fetchPasseInit())
        dispatch(login(result))
      })
      .catch(function(error) {
        console.log("Request failed", error)
      })
  }
}

export const deleteAddress = id_address => {
  var url = process.env.GATSBY_API_URL + "/index.php?controller=authentification"
  return (dispatch, getState) => {
    dispatch(fetchPasseInit())
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        action: "deleteAddress",
        id_address: id_address,
        email: getState().identifiants.identifiants.email,
        hash: getState().identifiants.identifiants.hash,
        site: process.env.SITE,
      }),
    })
      .then(function(response) {
        dispatch(fetchPasseChanged()) // pour faire une rederiction
        return response.json()
      })
      .then(function(result) {
        dispatch(fetchPasseInit())
        dispatch(login(result))
      })
      .catch(function(error) {
        console.log("Request failed", error)
      })
  }
}

export const updateInfos = (data, id_order = null) => {
  var url = process.env.GATSBY_API_URL + "/index.php?controller=authentification"

  return (dispatch, getState) => {
    dispatch(fetchPasseInit())
    dispatch(clearError())
    dispatch(setLoading)
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        action: "updateInfos",
        email: getState().identifiants.identifiants.email,
        hash: getState().identifiants.identifiants.hash,
        site: process.env.SITE,
        data: data,
      }),
    })
      .then(function(response) {
        dispatch(fetchPasseChanged())
        return response.json()
      })
      .then(function(result) {
        if (id_order && result.orders.find(elem => elem.id_order === id_order)) {
          dispatch(removeAllProducts())
        }
        if (typeof result.message !== "undefined" && result.message === "mailused") {
          dispatch(setError("L'adresse mail est déjà utilisée !"))
          dispatch(clearLoading())
        } else {
          setTimeout(
            function() {
              dispatch(refreshAuthFalse())
              dispatch(fetchPasseInit())
              dispatch(login(result))
            }.bind(this),
            500
          )
        }
      })
      .catch(function(error) {
        console.log("Request failed", error)

        dispatch(logoutR())
      })
  }
}

export const updateMDP = (id_customer, data) => {
  var url = process.env.GATSBY_API_URL + "/index.php?controller=authentification"
  return dispatch => {
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        action: "updateMDP",
        id_customer: id_customer,
        site: process.env.SITE,
        data: data,
      }),
    })
      .then(function(response) {
        return response.json()
      })
      .then(function(result) {
        dispatch(login(result))
      })
      .catch(function(error) {
        console.log("Request failed", error)
      })
  }
}

export const SET_ERROR = "SET_ERROR"
export const CLEAR_ERROR = "CLEAR_ERROR"
export const SET_LOADING = "SET_LOADING"
export const CLEAR_LOADING = "CLEAR_LOADING"
export const setError = error => {
  return {
    type: SET_ERROR,
    error,
  }
}
export const clearError = () => {
  return {
    type: CLEAR_ERROR,
  }
}
export const setLoading = error => {
  return {
    type: SET_LOADING,
    error,
  }
}
export const clearLoading = () => {
  return {
    type: CLEAR_LOADING,
  }
}
