import { combineReducers } from "redux"
import {
  SET_ID_ORDER,
  SET_REHAUSSE_COLOR,
  RESET_PAGE_PRODUCT,
  SET_BAGUETTE_DOUBLO,
  MJS_SERVICE,
  SET_CARTON_BARRIERE,
  SET_KIT_MONTAGE,
  SET_DIMENSIONS,
  SET_DIMENSIONS_INVALID,
  SET_DIMENSIONS_INTER,
  SET_DELIVERY_METHOD,
  SET_INVOICE_ADDRESS,
  SET_DELIVERY_ADDRESS,
  UPDATE_PRODUCT,
  ADD_PRODUCT,
  REMOVE_PRODUCT,
  REMOVE_ALL_PRODUCTS,
  REMOVE_ALL_PRODUCTS_RESELLER,
  LOGOUT,
} from "../actions"
import { SET_ERROR, CLEAR_ERROR, SET_LOADING, CLEAR_LOADING } from "../actions/auth"
import {
  SET_PP_OUVERTURES,
  SET_PP_MARGE_B,
  SET_PP_MARGE_D,
  SET_PP_MARGE_G,
  SET_PP_MARGE_H,
  SET_PP_TYPE,
  SET_PP,
  SET_PP_INTER_H,
  SET_PP_INTER_L,
} from "../actions/passe"
import deepEqual from "deep-equal"

export const getFraisPort = (products, modeLivraison) => {
  let sum = 0
  let onlyEchantillon = true
  for (const product of products) {
    if (product.name_product.indexOf("E") !== 0 && !product.echantillon) {
      // n'est pas un échantillon
      onlyEchantillon = false
    }

    sum += product.qty * product.price
  }
  return onlyEchantillon || sum >= 900 || modeLivraison === 9 || modeLivraison === 10 ? 0 : 60
}

const cart = (
  state = {
    products: [],
    recapPaiement: [],
    fraisPort: 0,
    modeLivraison: 11,
    deliveryAddress: null,
    invoiceAddress: null,
    paypalOrderId: null,
  },
  action
) => {
  switch (action.type) {
    case "ADD_PRODUCT": {
      const newProduct = {
        ...action.payload,
        position: state.products.reduce((acc, cur) => (acc < cur.position ? cur.position : acc), 0) + 1,
      }
      const newProducts = [...state.products]
      newProducts.unshift(newProduct)

      return {
        ...state,
        products: newProducts,
        currentProduct: action.payload,
        fraisPort: getFraisPort(newProducts, state.modeLivraison),
      }
    }

    case SET_DELIVERY_ADDRESS:
      return { ...state, deliveryAddress: action.id_address }
    case SET_INVOICE_ADDRESS:
      return { ...state, invoiceAddress: action.id_address }
    case UPDATE_PRODUCT: {
      const productrmvd = state.products.find(product => product._id == action.payload._id)
      const newProductUpd = {
        ...productrmvd,
        qty: action.payload.qty,
      }
      const newProductsUpd = state.products.filter(product => product._id !== action.payload._id)
      newProductsUpd.unshift(newProductUpd)
      return {
        ...state,
        products: newProductsUpd,
        currentProduct: { ...action.payload },
        fraisPort: getFraisPort(newProductsUpd, state.modeLivraison),
      }
    }
    case "SET_PAYPAL_ORDERID": {
      return {
        ...state,
        paypalOrderId: action.payload,
      }
    }
    case "REMOVE_PRODUCT": {
      const newProductsRm = state.products.filter(p => {
        return p._id !== action.payload.product._id
      })

      return {
        ...state,
        products: newProductsRm,
        fraisPort: getFraisPort(newProductsRm, state.modeLivraison),
      }
    }
    case LOGOUT:
    case "REMOVE_ALL_PRODUCTS": {
      return {
        ...state,
        products: [],
        recapPaiement: action.payload,
        paypalOrderId: null,
        fraisPort: 0,
      }
    }
    case SET_DELIVERY_METHOD:
      return {
        ...state,
        modeLivraison: action.id_method,
        fraisPort: getFraisPort(state.products, action.id_method),
      }
    case "persist/REHYDRATE": {
      var today = new Date()
      var tomorrow = new Date()
      tomorrow.setDate(today.getDate() - 5)
      var boolDateFind = false

      if (action && action.payload && action.payload.cart && action.payload.cart.products) {
        action.payload.cart.products.map(product => (new Date(product.dateAjout).getTime() < tomorrow ? (boolDateFind = true) : (boolDateFind = false)))
      } else {
        boolDateFind = true
      }

      if (boolDateFind) {
        return { ...state, products: [], currentProduct: {}, fraisPort: 0 }
      } else {
        const FilteredProducts = action.payload.cart.products
        return {
          ...state,
          products: FilteredProducts,
          currentProduct: {},
          fraisPort: getFraisPort(FilteredProducts, state.modeLivraison),
        }
      }
    }
  }
  return state
}
const resellerCart = (
  state = {
    products: [],
  },
  action
) => {
  switch (action.type) {
    case ADD_PRODUCT: {
      const newProduct = {
        ...action.payload,
        position: state.products.reduce((acc, cur) => (acc < cur.position ? cur.position : acc), 0) + 1,
      }
      const newProducts = [...state.products]
      newProducts.unshift(newProduct)

      return {
        ...state,
        products: newProducts,
        currentProduct: action.payload,
        fraisPort: getFraisPort(newProducts, state.modeLivraison),
      }
    }
    case UPDATE_PRODUCT: {
      const productrmvd = state.products.find(product => product._id == action.payload._id)
      const newProductUpd = {
        ...productrmvd,
        qty: action.payload.qty,
      }
      const newProductsUpd = state.products.filter(product => product._id !== action.payload._id)
      newProductsUpd.unshift(newProductUpd)
      return {
        ...state,
        products: newProductsUpd,
        fraisPort: getFraisPort(newProductsUpd, state.modeLivraison),
      }
    }

    case REMOVE_PRODUCT: {
      const newProductsRm = state.products.filter(p => {
        return p._id !== action.payload.product._id
      })

      return {
        ...state,
        products: newProductsRm,
        fraisPort: getFraisPort(newProductsRm, state.modeLivraison),
      }
    }
    case LOGOUT:
    case REMOVE_ALL_PRODUCTS_RESELLER:
    case REMOVE_ALL_PRODUCTS: {
      return {
        ...state,
        products: [],
      }
    }
  }
  return state
}

const ColorR = (state = { colors: [] }, action) => {
  switch (action.type) {
    case "ADD_COLOR":
      return { ...state, colors: action.payload }
  }
  return state
}

const LoginR = (state = { identifiants: null, lastUpdate: 0, error: null }, action) => {
  switch (action.type) {
    case SET_ERROR:
      return { ...state, error: action.error }
    case CLEAR_ERROR:
      return { ...state, error: null }
    case "LOGIN":
      return {
        ...state,
        identifiants: action.payload,
        lastUpdate: Math.round(new Date() / 1000),
      }
    case "FETCH_PASSE_INIT":
      return { ...state, lastUpdate: Math.round(new Date() / 1000) }
    case LOGOUT:
      return { ...state, identifiants: action.payload }
  }
  return state
}

const Loading = (state = { loading: false, isChanged: false, isRefresh: false }, action) => {
  switch (action.type) {
    case "FETCH_PRODUCTS_PENDING":
      return { ...state, loading: true }
    case "FETCH_PRODUCTS_SUCCESS":
      return { ...state, loading: false }
    case "FETCH_PRODUCTS_ERROR":
      return { ...state, loading: false }
    case "FETCH_PASSE_CHANGED":
      return { ...state, isChanged: true }
    case "FETCH_PASSE_INIT":
      return { ...state, isChanged: false }
    case "REFRESH_AUTH_FALSE":
      return { ...state, isRefresh: false }
    case "REFRESH_AUTH_TRUE":
      return { ...state, isRefresh: true }
    case SET_LOADING:
      return { ...state, loading: true }
    case CLEAR_LOADING:
      return { ...state, loading: false }
  }
  return state
}

const Paiement = (state = { modePaiement: "", idOrder: "" }, action) => {
  // alert("----->"+action.type)
  switch (action.type) {
    case "MODE_PAIEMENT":
      return { ...state, modePaiement: action.payload }
    case SET_ID_ORDER:
      return { ...state, idOrder: action.payload }
    case "REMOVE_ALL_PRODUCTS":
    case "REMOVE_PRODUCT":
    case "ADD_PRODUCT":
    case "UPDATE_PRODUCT":
      return { ...state, idOrder: "" }
  }
  return state
}

const pp = { type: null, pp: null, margeH: 0, margeB: 0, margeD: 0, margeG: 0, interL: 0, interH: 0, ouvertures: [1, 1] }
const PageProduit = (
  state = {
    baguette: null,
    rehausse: null,
    rehausse_color: null,
    doublo: null,
    service: "coupe_assemble_isorel",
    kitMontage: false,
    cartonBarriere: false,
    dimensions_valid: true,
    largeur: 40,
    hauteur: 50,
    pp,
  },
  action
) => {
  switch (action.type) {
    case RESET_PAGE_PRODUCT:
      return {
        ...state,
        baguette: action.baguette,
        doublo: null,
        rehausse: null,
        pp,
        rehausse_color: null,
        service: "coupe_assemble_isorel",
        kitMontage: false,
        cartonBarriere: false,
        dimensions_valid: true,
        largeur: action.largeur,
        hauteur: action.hauteur,
        dimensions_inter: false,
      }
    case SET_PP:
      return { ...state, pp: { ...state.pp, pp: action.pp } }
    case SET_PP_TYPE:
      return {
        ...state,
        pp: {
          ...state.pp,
          type: action.pp_type,
          ouvertures: [null, "simple", "aucun"].includes(action.pp_type) ? [1, 1] : state.pp.ouvertures,
          margeH: action.pp_type == "aucun" ? 0 : 5,
          margeB: action.pp_type == "aucun" ? 0 : 5,
          margeG: action.pp_type == "aucun" ? 0 : 5,
          margeD: action.pp_type == "aucun" ? 0 : 5,
          interL: action.pp_type == "aucun" ? 0 : 5,
          interH: action.pp_type == "aucun" ? 0 : 5,
        },
        isorel: action.pp_type !== "aucun" ? true : state.isorel,
      }
    case SET_PP_MARGE_H:
      return { ...state, pp: { ...state.pp, margeH: action.marge } }
    case SET_PP_MARGE_G:
      return { ...state, pp: { ...state.pp, margeG: action.marge } }
    case SET_PP_MARGE_D:
      return { ...state, pp: { ...state.pp, margeD: action.marge } }
    case SET_PP_MARGE_B:
      return { ...state, pp: { ...state.pp, margeB: action.marge } }
    case SET_PP_INTER_H:
      return { ...state, pp: { ...state.pp, interH: action.marge } }
    case SET_PP_INTER_L:
      return { ...state, pp: { ...state.pp, interL: action.marge } }
    case SET_PP_OUVERTURES:
      return {
        ...state,
        pp: {
          ...state.pp,
          ouvertures: action.ouvertures,
          type: deepEqual([1, 1], action.ouvertures) ? "simple" : "multivues",
          margeH: 5,
          margeB: 5,
          margeG: 5,
          margeD: 5,
          interL: 5,
          interH: 5,
        },
      }
    case SET_REHAUSSE_COLOR:
      return { ...state, rehausse_color: action.color, rehausse: action.key }
    case SET_BAGUETTE_DOUBLO:
      return { ...state, doublo: action.baguette }
    case MJS_SERVICE: {
      let cartonBarriere = state.cartonBarriere
      let kitMontage = state.kitMontage
      if (action.service == "precoupe") cartonBarriere = false
      if (action.service !== "precoupe") kitMontage = false
      return { ...state, service: action.service, kitMontage, cartonBarriere }
    }
    case SET_CARTON_BARRIERE:
      return { ...state, cartonBarriere: action.cartonBarriere }
    case SET_KIT_MONTAGE:
      return { ...state, kitMontage: action.kitMontage }
    case SET_DIMENSIONS:
      return { ...state, largeur: action.largeur, hauteur: action.hauteur, dimensions_valid: true, service: null }
    case SET_DIMENSIONS_INVALID:
      return { ...state, dimensions_valid: false }
    case SET_DIMENSIONS_INTER:
      return { ...state, dimensions_inter: action.bool }
    default:
      return state
  }
}

const allReducers = combineReducers({
  cart,
  resellerCart,
  colorAdd: ColorR,
  identifiants: LoginR,
  loading: Loading,
  paiement: Paiement,
  PageProduit,
})

export default allReducers
