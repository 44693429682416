export const ADD_PRODUCT = "ADD_PRODUCT"
export const addProduct = product => {
  return {
    type: ADD_PRODUCT,
    payload: product,
  }
}

export const UPDATE_PRODUCT = "UPDATE_PRODUCT"
export const updateProduct = product => {
  return {
    type: UPDATE_PRODUCT,
    payload: product,
  }
}

export const REMOVE_PRODUCT = "REMOVE_PRODUCT"
export const removeProduct = product => {
  return {
    type: REMOVE_PRODUCT,
    payload: { product: product },
  }
}

export const ADD_COLOR = "ADD_COLOR"
export const addColor = color => {
  return {
    type: ADD_COLOR,
    payload: color,
  }
}

export const LOGIN = "LOGIN"
export const login = identifiant => {
  return {
    type: LOGIN,
    payload: identifiant,
  }
}

export const LOGOUT = "LOGOUT"
export const logoutR = () => {
  return {
    type: LOGOUT,
    payload: null,
  }
}

export const FETCH_PRODUCTS_PENDING = "FETCH_PRODUCTS_PENDING"
export const fetchProductsPending = () => {
  return {
    type: FETCH_PRODUCTS_PENDING,
    payload: null,
  }
}

export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS"
export const fetchProductsSuccess = () => {
  return {
    type: FETCH_PRODUCTS_SUCCESS,
    payload: null,
  }
}

export const FETCH_PRODUCTS_ERROR = "FETCH_PRODUCTS_ERROR"
export const fetchProductsError = () => {
  return {
    type: FETCH_PRODUCTS_ERROR,
    payload: null,
  }
}

export const FETCH_PASSE_CHANGED = "FETCH_PASSE_CHANGED"
export const fetchPasseChanged = () => {
  return {
    type: FETCH_PASSE_CHANGED,
    payload: null,
  }
}

export const FETCH_PASSE_INIT = "FETCH_PASSE_INIT"
export const fetchPasseInit = () => {
  return {
    type: FETCH_PASSE_INIT,
    payload: null,
  }
}

export const REMOVE_ALL_PRODUCTS = "REMOVE_ALL_PRODUCTS"
export const removeAllProducts = data => {
  return {
    type: REMOVE_ALL_PRODUCTS,
    payload: data,
  }
}
export const REMOVE_ALL_PRODUCTS_RESELLER = "REMOVE_ALL_PRODUCTS_RESELLER"
export const removeAllProductsReseller = () => ({
  type: REMOVE_ALL_PRODUCTS_RESELLER,
})

export const REFRESH_AUTH_TRUE = "REFRESH_AUTH_TRUE"
export const refreshAuthTrue = () => {
  return {
    type: REFRESH_AUTH_TRUE,
    payload: null,
  }
}

export const REFRESH_AUTH_FALSE = "REFRESH_AUTH_FALSE"
export const refreshAuthFalse = () => ({
  type: REFRESH_AUTH_FALSE,
})
export const MODE_PAIEMENT = "MODE_PAIEMENT"
export const modePaiement = data => {
  return {
    type: MODE_PAIEMENT,
    payload: data,
  }
}

export const MJS_SERVICE = "MJS_SERVICE"
export const mjsService = service => {
  return {
    type: MJS_SERVICE,
    service,
  }
}

export const SET_ID_ORDER = "SET_ID_ORDER"
export const setIdOrder = data => {
  return {
    type: SET_ID_ORDER,
    payload: data,
  }
}

export const SET_PAYPAL_ORDERID = "SET_PAYPAL_ORDERID"
export const setPaypalOrderid = data => {
  return {
    type: SET_PAYPAL_ORDERID,
    payload: data,
  }
}

export const SET_REHAUSSE_COLOR = "SET_REHAUSSE_COLOR"
export const setRehausseColor = (key, color) => {
  return {
    type: SET_REHAUSSE_COLOR,
    key,
    color,
  }
}
export const RESET_PAGE_PRODUCT = "RESET_PAGE_PRODUCT"
export const resetPageProduct = (baguette, hauteur, largeur) => {
  return {
    type: RESET_PAGE_PRODUCT,
    baguette,
    hauteur,
    largeur,
  }
}
export const SET_BAGUETTE_DOUBLO = "SET_BAGUETTE_DOUBLO"
export const setBaguetteDoublo = baguette => {
  return {
    type: SET_BAGUETTE_DOUBLO,
    baguette,
  }
}

export const SET_CARTON_BARRIERE = "SET_CARTON_BARRIERE"
export const setCartonBarriere = cartonBarriere => {
  return {
    type: SET_CARTON_BARRIERE,
    cartonBarriere,
  }
}

export const SET_KIT_MONTAGE = "SET_KIT_MONTAGE"
export const setKitMontage = kitMontage => {
  return {
    type: SET_KIT_MONTAGE,
    kitMontage,
  }
}

export const SET_DIMENSIONS = "SET_DIMENSIONS"
export const setDimensions = (largeur, hauteur) => {
  return {
    type: SET_DIMENSIONS,
    largeur,
    hauteur,
  }
}

export const SET_DIMENSIONS_INVALID = "SET_DIMENSIONS_INVALID"
export const setDimensionsInvalid = () => {
  return {
    type: SET_DIMENSIONS_INVALID,
  }
}

export const SET_DIMENSIONS_INTER = "SET_DIMENSIONS_INTER"
export const setDimensionsInter = bool => {
  return {
    type: SET_DIMENSIONS_INTER,
    bool,
  }
}

export const SET_DELIVERY_METHOD = "SET_DELIVERY_METHOD"
export const setDeliveryMethod = id_method => {
  return {
    type: SET_DELIVERY_METHOD,
    id_method,
  }
}
export const SET_DELIVERY_ADDRESS = "SET_DELIVERY_ADDRESS"
export const setDeliveryAddress = id_address => {
  return {
    type: SET_DELIVERY_ADDRESS,
    id_address,
  }
}
export const SET_INVOICE_ADDRESS = "SET_INVOICE_ADDRESS"
export const setInvoiceAddress = id_address => {
  return {
    type: SET_INVOICE_ADDRESS,
    id_address,
  }
}
